<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the maximum amount of
        <chemical-latex content="S8" />
        that could be produced by reacting
        <number-value :value="sampleMass" unit="\text{g}" />
        of each reagent.
      </p>

      <p class="mb-4 pl-6">
        <chemical-latex content="8 SO2 + 16 H2S -> 3 S8 + 16 H2O" />
      </p>

      <calculation-input
        v-model="inputs.massS8"
        prepend-text="$\text{Mass of }\ce{S8:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'Question68',
  components: {
    ChemicalLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massS8: null,
      },
    };
  },
  computed: {
    sampleMass() {
      return this.taskState.getValueBySymbol('sampleMass').content;
    },
  },
};
</script>
